import * as React from 'react'
import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore,
  GallerySection,
} from '../components/styles'
import styled, { css } from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'

import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Form from '../components/Form'
import FAQs from '../components/FAQs'

import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'

import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'
import YouTube from 'react-youtube'
import { ContactDetails } from './contact-us'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'

export const Video = styled(YouTube)`
  margin-top: 25px;
  width: 90vw;
  height: 50.75vw;
  max-height: 576px;
  max-width: 1024px;
  border-radius: 8px;
`

const Logos = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 900px;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin: -50px auto 0;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    .gatsby-image-outer-wrapper:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }

  .gatsby-image-outer-wrapper {
    width: 80px;
    height: auto;
  }
`

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

export const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 15rem;
  }

  @media (max-width: 480px) {
    margin-top: 18rem;
  }

  section {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    figure {
      width: calc(100vw / 8);
    }
  }

  h2 {
    font-weight: 500;
    color: ${(props) => props.theme.colors.dark};
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    a {
      text-decoration: underline;
    }

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const HomeHeader = styled.header`
  height: 600px;
  padding-top: 9rem;
  padding-left: 15rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;
  min-height: 550px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 900px) and (max-width: 1280px) {
    figure {
      .gatsby-image-wrapper {
        overflow: visible !important;
        img {
          height: 650px !important;
        }
      }
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.85;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 8rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
    figure,
    .gatsby-image-wrapper {
      height: 650px;
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(2) {
      margin-top: 8rem;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2) {
      margin-top: 10rem;
    }
  }

  figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${(props) =>
      props.slant &&
      css`
        transform: skewY(-6deg);
        transform-origin: left top 0px;
      `}
    overflow: hidden;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  > div {
    > h2,
    > p {
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    }
  }

  h2 {
    color: #fff;
    font-weight: 700;
    max-width: 700px;
    line-height: 90px;
    font-size: 80px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    padding-right: 2rem;
    max-width: 600px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 11rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    padding-top: 12rem;
  }
`

export const FormContainer = styled.div`
  width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);

  > div {
    padding: 1.5rem 2rem !important;
    border-top: 0;
  }
  h2 {
    color: #333 !important;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`
export const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;

  > h2 {
    text-align: center;
    font-size: 3rem;
    padding: 0 8px;

    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
    i {
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  background: #000;
  img {
    opacity: 0.3 !important;
  }
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const info = [
  {
    question: 'Custom designed for you',
    answer: `We understand that every lifestyle is unique. Your home, needs and situation are all factors that we consider with every new construction project. That's why we custom design your patio, for you, to create something you love.`,
  },
  {
    question: 'Complete project management',
    answer: `We all lead hectic lives, that's why we're not just builders. Here at Just Patios we'll take care of the entire project from start all the way through completion.`,
  },
  {
    question: 'Storm damage specialists',
    answer: `We're experts in storm damage and can work to assist you in a full range of services.`,
  },
  {
    question: 'Fully licenced and insured',
    answer: `Our builders are fully licenced and insured to guarantee the highest quality craftsmanship with every project.`,
  },
  {
    question: 'We can arrange finance',
    answer: `Just let us know and we can work with you to arrange financing options for your new patio, carport or room enclosure.`,
  },
  {
    question: 'On schedule, on time',
    answer: `Our guarantee to you! We know how much you're looking forward to enjoying your new patio and so strive to always be on schedule and on time.`,
  },
]

export const AboutContact = (props) => <Contact {...props} />

/* prettier-ignore */
interface IProps {
  data: {
    womanWaterfall: {
      childImageSharp: {
        fluid: object
      }
    };
    woodsWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    waterBodyWoman: {
      childImageSharp: {
        fluid: object
      }
    };
    faceCloseUp: {
      childImageSharp: {
        fluid: object
      }
    };
    drSellars: {
      childImageSharp: {
        fluid: object
      }
    };
    nonSurgical: {
      childImageSharp: {
        fluid: object
      }
    };
    asaps: {
      childImageSharp: {
        fluid: object
      }
    };
    asps: {
      childImageSharp: {
        fluid: object
      }
    };
    fracs: {
      childImageSharp: {
        fluid: object
      }
    };
  }
}

export default ({ data }: IProps) => {
  return (
    <Layout header="dark">
      <Helmet>
        <title>Gallery - QLD Sports Aviators</title>
        <meta
          name="description"
          content="Check out some of our best paragliding, hang gliding and microlighting photos and get a taste of the adventure."
        />
      </Helmet>
      <GallerySection style={{ marginTop: 75 }}>
        <h2>Flight Gallery</h2>

        <GalleryContainer>
          {data.gallery.edges
            .sort((x, y) => {
              if (
                x.node.childImageSharp === null ||
                y.node.childImageSharp === null
              ) {
                return 0
              }
              return y.node.childImageSharp.fixed.src.localeCompare(
                x.node.childImageSharp.fixed.src
              )
            })
            .map(
              (image, i) =>
                image.node.childImageSharp && (
                  <GalleryImage modal={image.node.childImageSharp.modal}>
                    <Img fixed={image.node.childImageSharp.fixed} />
                  </GalleryImage>
                )
            )}
        </GalleryContainer>
      </GallerySection>
    </Layout>
  )
}

export const query = graphql`
  query GalleryPageQuery {
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/flight" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
